<template>
  <div class="m_picTextPublic">
    <div class="courseInfo">
      <div class="PictureText">
        {{ ImageAndTextInfo.courseContName }}
      </div>
      <div class="sourceInfo">
        <div>来源：{{ ImageAndTextInfo.source }}</div>
        <div>发布时间：{{ ImageAndTextInfo.releaseTime }}</div>
      </div>
      <van-divider />
      <div class="sourceTips">
        阅读文章全部内容并通过随堂测试后即可学习完成，本页总字数：{{
          ImageAndTextInfo.wordCount
        }}字，预计阅读时长：{{ getTimes(ImageAndTextInfo.totalSeconds) }}
      </div>
      <van-divider />
      <div class="couresContent" v-html="ImageAndTextInfo.courseCont"></div>
    </div>
    <div class="progressTools">
      <div class="left">
        <div class="top">
          <div>当前学习进度</div>
          <div>
            {{
              ImageAndTextInfo.isWanCheng
                ? ImageAndTextInfo.isTongGuo
                  ? "完成"
                  : "待考试"
                : percentage.toFixed(0) + "%"
            }}
          </div>
        </div>
        <div class="bottom">
          <van-progress
            track-color="rgba(255, 255, 255, 0.6)"
            color="#ffffff"
            :show-pivot="false"
            :percentage="percentage"
          />
        </div>
      </div>
      <div class="right" @click="toTop">
        <svg-icon icon="icon-rongqi" />
        顶部
      </div>
    </div>
  </div>
</template>
<script>
import SvgIcon from "@/components/svgIcon.vue";
import { getGongXuUserCourseById } from "@/api/publicDemandController";
import { showModel } from "@/utils/tools";
import {
  getStudyProcessEnd,
  getStudyProcessStart,
  getStudyProcessUpdate,
} from "@/api/specializedController";
import * as storage from "@/utils/storage";

export default {
  name: "m_videoPlayer",
  components: { SvgIcon },
  activated() {
    console.log("onshow播放");
  },
  mounted() {},
  computed: {
    getTimes() {
      return (t) => {
        let h = parseInt((t / 60 / 60) % 24);
        let m = parseInt((t / 60) % 60);
        let s = parseInt(t % 60);

        // 根据h、m、s的值构建返回字符串
        let result = s.toString() + "秒";
        if (m > 0) {
          result = m.toString() + "分钟" + result;
        }
        if (h > 0) {
          result = h.toString() + "小时" + result;
        }

        return result;
      };
    },
    yearId() {
      return storage.getStore("yearId");
    },
  },
  data() {
    return {
      courseContId: 0,
      scrollTop: 0,
      actionBtnRight: 0,
      ImageAndTextInfo: {},
      percentage: 0,
      precossTime: 0,
      countdown: null,
      editPlayTimer: null,
      studyProcessId: 0,
    };
  },
  created() {
    this.courseContId = this.$route.query.courseContId;
    this.getPublicInfo();
  },
  beforeDestroy() {
    clearInterval(this.countdown);
    this.countdown = null;
    clearInterval(this.editPlayTimer);
    this.editPlayTimer = null;
  },
  methods: {
    //回到顶部
    toTop() {
      const timer = setInterval(() => {
        // 文档滚动的距离
        const top = document.documentElement.scrollTop;
        // 设置步长，便于实现动画滚动
        const speed = Math.ceil(top / 10);
        if (document.documentElement.scrollTop > 0) {
          document.documentElement.scrollTop -= speed;
        } else {
          // 当返回顶部时，清除定时器
          clearInterval(timer);
        }
      }, 5);
    },
    // 获取公需课详情
    getPublicInfo() {
      getGongXuUserCourseById({
        courseContId: this.courseContId,
        trainingPlanId: this.yearId,
      }).then((res) => {
        this.ImageAndTextInfo = res.data;
        if (res.data.isWanCheng) {
          this.percentage = 100;
        }
        if (res.data.isWanCheng && !res.data.isTongGuo) {
          showModel({
            content: "恭喜您已经完成所有课程学习，请前往考试吧",
            showClose: true,
            showCancelButton: true,
            confirmButtonText: "前往考试",
            closeModal: false,
            confirm: () => {
              console.log("前往考试");
              this.keepAliveNavigation(
                "examination?courseId=" + res.data.studyCourseId
              );
            },
          });
        }
        if (!(res.data.isWanCheng || res.data.isTongGuo)) {
          this.countdownStart();
        }
      });
    },
    // 开始学习
    countdownStart() {
      const that = this;
      getStudyProcessStart({
        ppXmWjbzp5b8: this.ImageAndTextInfo.courseContId,
        c3R1ZHlDb3Vyc2VJZA: this.ImageAndTextInfo.studyCourseId,
        trainingId: this.yearId,
      }).then((res) => {
        if (res.data.playingTime) {
          that.precossTime = res.data.playingTime;
        }
        this.studyProcessId = res.data.studyProcessId;
        // 学习时间记录
        this.countdown = setInterval(() => {
          if (that.precossTime < that.ImageAndTextInfo.totalSeconds) {
            that.percentage = Number(
              (that.precossTime /
                parseFloat(that.ImageAndTextInfo.totalSeconds)) *
                100
            );
            that.precossTime += 0.1;
          } else {
            that.precossTime = Number(that.ImageAndTextInfo.totalSeconds);
            clearInterval(this.countdown);
            that.countdown = null;
            clearInterval(this.editPlayTimer);
            that.editPlayTimer = null;
            // 学习结束请求
            getStudyProcessEnd({
              c3R1ZHlQcm9jZXNzSWQ: that.studyProcessId,
              trainingId: this.yearId,
            }).then((res) => {
              that.percentage = 100;
              that.ImageAndTextInfo.isWanCheng = true;
              if (res.data.state === 2) {
                showModel({
                  content: "恭喜您已经完成所有课程学习，请前往考试吧",
                  showClose: true,
                  showCancelButton: true,
                  confirmButtonText: "前往考试",
                  closeModal: false,
                  confirm: () => {
                    console.log("前往考试");
                    this.keepAliveNavigation(
                      "examination?courseId=" + res.data.courseId
                    );
                  },
                });
              }
            });
          }
        }, 100);
        // 每间隔2分钟上传一次时间
        if (!this.editPlayTimer) {
          this.editPlayTimer = setInterval(() => {
            that.setStudyProcessEdit();
          }, 120000);
        }
      });
    },
    // 记录时间请求
    setStudyProcessEdit() {
      getStudyProcessUpdate({
        c3R1ZHlQcm9jZXNzSWQ: this.studyProcessId,
        cgxhewluz1rpbwu: Math.ceil(this.precossTime),
        trainingId: this.yearId,
      }).then(() => {});
    },
  },
};
</script>
<style scoped lang="scss">
.courseInfo {
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 15%;

  .PictureText {
    color: #21252e;
    font-size: 16px;
    font-weight: 500;
  }

  .sourceInfo {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    color: #818496;
    font-size: 12px;
    margin: 12px auto;
  }

  .sourceTips {
    margin-bottom: 12px;
    font-size: 12px;
    color: #d71917;
  }

  .couresContent {
    color: #505363;
    font-size: 14px;
    text-indent: 2rem;
    overflow-x: hidden;
    ::v-deep section {
      width: 100% !important;
      max-width: 100%;
    }
  }
}
.progressTools {
  width: calc(100% - 30px);
  padding: 12px 16px;
  border-radius: 12px;
  position: fixed;
  bottom: 2%;
  left: 15px;
  box-sizing: border-box;
  background: #b50413;
  /* 上层投影 */
  box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05),
    0px 16px 24px 2px rgba(0, 0, 0, 0.04), 0px 8px 10px -5px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  .left {
    width: 100%;
    margin-right: 10px;
    .top {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
    }
    .bottom {
      margin-top: 5px;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 40px;
    min-width: 40px;
    color: #ffffff;
  }
}
</style>
